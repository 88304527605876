import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { useAuth } from "./context/AuthContext";
import Leads from "./components/Leads";
import Login from "./components/Login";
import DealDetail from "./components/DealDetail";
import { getCustomUiSdk } from "./utils/customUiSdk";

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = window.location.search;

  const queryParams = new URLSearchParams(location);
  const id = queryParams.get("id");

  console.log(`isAuthenticated: ${isAuthenticated}`);

  useEffect(() => {
    const initializeSdk = async () => {
      try {
        if (id) {
          await getCustomUiSdk(id);
        }
      } catch (error) {
        console.error("Error during SDK initialization", error);
      }
    };

    initializeSdk();
  }, [id, isAuthenticated]);

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Leads />} />
        <Route path="/deal" element={<DealDetail />} />
      </Routes>
    </Router>
  );
};

export default App;
