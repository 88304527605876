import React, { useEffect, useState } from "react";

import { useAuth } from "../context/AuthContext";
import { fetchDeal } from "../services/pipedriveService";
import { Deal } from "../types/Deal";

const DealDetail: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [deal, setDeal] = useState<Deal | null>(null);
  const [error, setError] = useState<string | null>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const selectedIds = queryParams.get("selectedIds");
  console.log(`selectedIds: ${selectedIds}`);

  useEffect(() => {
    const getDeal = async () => {
      try {
        if (isAuthenticated && selectedIds) {
          const response = await fetchDeal(selectedIds);
          setDeal(response.data);
        }
      } catch (err) {
        setError("Failed to fetch deal details");
      }
    };

    getDeal();
  }, [isAuthenticated, selectedIds]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!deal) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{deal.title}</h2>
      <p>{deal.person_id.name}</p>
    </div>
  );
};

export default DealDetail;
