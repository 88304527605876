import AppExtensionsSDK, {
  Command,
  Modal,
  View,
} from "@pipedrive/app-extensions-sdk";

let SDK: AppExtensionsSDK;
// Initialize SDK and set height. If SDK is already initialized, just return it.
export const getCustomUiSdk = async (id: string) => {
  try {
    if (SDK) return SDK;
    console.info("Initializing SDK");
    SDK = await new AppExtensionsSDK({ identifier: id }).initialize({
      size: { height: 550 },
    });
    return SDK;
  } catch (e) {
    console.error("Error during SDK initialization", e);
  }
};

export const getSignedToken = async (sdk: AppExtensionsSDK) => {
  console.info("Getting signed token");
  const { token } = await sdk.execute(Command.GET_SIGNED_TOKEN);
  return token;
};

export const hideFloatingWindow = async (sdk: AppExtensionsSDK) => {
  console.info("Hiding floating window");
  await sdk.execute(Command.HIDE_FLOATING_WINDOW, {});
};

export const showFloatingWindow = async (sdk: AppExtensionsSDK) => {
  console.info("Showing floating window");
  await sdk.execute(Command.SHOW_FLOATING_WINDOW, {});
};

export const openActivityModal = async (sdk: AppExtensionsSDK) => {
  console.info("Opening activity modal");
  await sdk.execute(Command.OPEN_MODAL, {
    type: Modal.ACTIVITY,
  });
};

export const redirectToContact = async (sdk: AppExtensionsSDK, id: string) => {
  console.info(`Redirecting to ${id}`);
  await sdk.execute(Command.REDIRECT_TO, { view: View.CONTACTS, id });
};

export const setNotification = async (
  sdk: AppExtensionsSDK,
  number: number
) => {
  console.info(`Updating notification count to ${number}`);
  await sdk.execute(Command.SET_NOTIFICATION, {
    number,
  });
};
