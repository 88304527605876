import axios from "axios";

import {
  DealsResponse,
  DealResponse,
  LeadsResponse,
  LeadResponse,
} from "../types/Responses";

const apiUrl = process.env.REACT_APP_BACKEND_URL!;

export const fetchLeads = async (): Promise<LeadsResponse> => {
  const response = await axios.get(`${apiUrl}pipedrive/leads`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchLead = async (id: string): Promise<LeadResponse> => {
  const response = await axios.get(`${apiUrl}pipedrive/leads/${id}`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchDeals = async (): Promise<DealsResponse> => {
  const response = await axios.get(`${apiUrl}pipedrive/deals`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchDeal = async (id: string): Promise<DealResponse> => {
  const response = await axios.get(`${apiUrl}pipedrive/deals/${id}`, {
    withCredentials: true,
  });
  return response.data;
};
