import React from "react";

const Login: React.FC = () => {
  return (
    <>
      <a target="_blank" rel="noreferrer" href="auth/login">
        Please re-authorize
      </a>{" "}
      and then{" "}
      <button
        onClick={() => {
          window.location.reload();
        }}
        style={{
          textDecoration: "underline",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        refresh the page.
      </button>
    </>
  );
};

export default Login;
