import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import axios from "axios";

interface AuthContextProps {
  isAuthenticated: boolean;
  userId: string | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    const checkAuth = async (userId: string) => {
      axios
        .get(`/auth/status?userId=${userId}`, { withCredentials: true })
        .then((response) => {
          setIsAuthenticated(response.data.isAuthenticated);
        })
        .catch(() => {
          setIsAuthenticated(false);
        });
    };

    const queryParams = new URLSearchParams(window.location.search);
    const userIdFromQuery = queryParams.get("userId");

    if (userIdFromQuery) {
      setUserId(userIdFromQuery);
      checkAuth(userIdFromQuery);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
