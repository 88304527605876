import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { fetchLeads } from "../services/pipedriveService";

const Leads: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [leads, setLeads] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const retrieveLeads = async () => {
      try {
        if (isAuthenticated) {
          const leadData = await fetchLeads();
          setLeads(leadData.data);
        }
      } catch (err) {
        console.error("Error fetching leads:", err);
        setError("Failed to fetch leads. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    retrieveLeads();
  }, [isAuthenticated]);

  if (loading) {
    return <div>Loading leads...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Leads</h2>
      {leads.length === 0 ? (
        <p>No leads found.</p>
      ) : (
        <ul>
          {leads.map((lead) => (
            <li key={lead.id}>
              <Link to={`/leads/${lead.id}`}>{lead.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Leads;
